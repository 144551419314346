import React from "react";
import { useEffect } from "react";
import { removeData } from "../../_services/localStore";
import { setCookie } from "../../_helpers";
import { GlobalConfig as config } from "../../config";
import FullscreenLoader from "src/Components/Loaders/FullscreenLoader/FullscreenLoader";

const ClearSession = () => {
  useEffect(() => {
    async function clearSessionsData() {
      await removeData(config.LOCAL_STORAGE.KEY.USER);
      await setCookie(`${config.COOKIES_KEY.session_id}`, "", 0);
      window.location = window.origin;
    }
    clearSessionsData();
  }, []);
  return <FullscreenLoader />;
};

export default ClearSession;
