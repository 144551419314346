import React from "react";
import Button from "react-bootstrap/Button";
import video from "src/assets/icons/video.svg";
import mutedMic from "src/assets/icons/muted-mic.svg";

import styles from "./requestingVideoCall.module.css";

import Draggable from "react-draggable";
import { publish } from "src/_helpers/custom-events";

const RequestingVideoCall = () => {
  function handleCancel() {
    publish("cancelRequestForVideoCall");
  }

  return (
    <Draggable>
      <div className={`${styles.primaryColor} ${styles.mainDiv}`}>
        <div
          className={`${styles.primaryColor} ${styles.topDiv} d-flex flex-column align-items-center justify-content-center pt-4`}
        >
          <h3>Abdulla</h3>
          <p>requesting video call...</p>
        </div>
        <div
          className={`d-flex align-items-center justify-content-center mt-5`}
        >
          <div className={`${styles.middleDiv} gap-2`}>
            <img className="ms-2 my-auto" src={mutedMic} alt="mic" />
            <p className={`me-2 ${styles.microphoneMutedText} my-auto`}>
              Your microphone is muted
            </p>
          </div>
        </div>
        <div
          className={`d-flex align-items-baseline justify-content-center mt-5 gap-2 pt-3 pb-1 ${styles.bottomDiv}`}
        >
          <img src={video} alt="video" />
          <p> Requesting to switch to a video call...</p>
          <Button className={`${styles.cancel}`} onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      </div>
    </Draggable>
  );
};

export default RequestingVideoCall;
