import HookZAppLogo from "src/assets/images/hookzapp-logo.png";
const env = process.env;
const APPNAME = env.REACT_APP_APPNAME || "HOOKZAPP";
const HOOKZAPP_PRIMARY_COLOR = "#FF3400";
const HOOKZAPP_SECONDARY_COLOR = "#439BFF";
const HOOKZAPP_TERTIARY_COLOR = "#F6F6F6"; // "#00000029"
let PRIMARY_COLOR;
let SECONDARY_COLOR;
let TERTIARY_COLOR;
let LOGO;
if (APPNAME.toLowerCase() === "hookzapp") {
  PRIMARY_COLOR = HOOKZAPP_PRIMARY_COLOR;
  SECONDARY_COLOR = HOOKZAPP_SECONDARY_COLOR;
  TERTIARY_COLOR = HOOKZAPP_TERTIARY_COLOR;
  LOGO = HookZAppLogo;
}
export default {
  APPNAME,
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  TERTIARY_COLOR,
  LOGO,
  baseUrl: env.REACT_APP_API_BASE_URL,
  apiMUrl: env.REACT_APP_APIM_URL,
  APIM_APIS: {
    fireBaseSessionBuild: `${env.REACT_APP_APIM_URL}fireBaseSessionBuild`,
    getFireBaseSession: `${env.REACT_APP_APIM_URL}getFireBaseSession`,
    syncContactsOnAppOpenViaCS: `${process.env.REACT_APP_APIM_URL}syncContactsOnAppOpenViaCS`,
  },
  COMERA_BACKEND_URL: {
    userPepNodes: `${env.REACT_APP_API_COMERA_BACKEND_URL}users/pep-nodes`,
  },
  WEB_CHAT_URL: `${env.REACT_APP_COVERSE_URL}?session_id=`,
  WEB_CHAT_CLEAR_SESSION_URL: env.REACT_APP_CLEAR_SESSION_URL,
  baseRegUrl: env.REACT_APP_BASE_REGISTRATION_URL,
  aes_secret: env.REACT_APP_ENCRYPTION_DECRYPTION_SECRET,

  COOKIES_KEY: {
    session_id: "session_id",
  },
  LOCAL_STORAGE: {
    KEY: {
      USER: "user",
    },
  },

  MONGOOSE: {
    XMPPHost: env.REACT_APP_MONGOOSE_XMPPHost,
    get JID_POSTFIX() {
      return "@" + this.XMPPHost;
    },
    get GROUP_JID_POSTFIX() {
      return "@muclight." + this.XMPPHost;
    },
    get GROUP_lUSER() {
      return "muclight." + this.XMPPHost;
    },
  },

  FIREBASE_CONFIG: {
    apiKey: env.REACT_APP_FIREBASE_API_KEY,
    authDomain: env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: env.REACT_APP_FIREBASE_APP_ID
  },
  SEND_GRID_API_URL: env.REACT_APP_SEND_GRID_API_URL

};
