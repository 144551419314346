import { useEffect, useState } from "react";

const HomeStatic = () => {
  const [pathname, setPathname] = useState(
    window.location.pathname.toLowerCase()
  );

  useEffect(() => {
    if (pathname === "/" || pathname === "") {
      setPathname("index");
    } else if (pathname === "/terms-conditions.html") {
      setPathname("terms-conditions");
    } else if (pathname === "/privacy-policy.html") {
      setPathname("privacy-policy");
    }
  }, []);

  return (
    <>
      <iframe
        src={`/~/${pathname}.html`}
        style={{ width: "100%", height: "100vh" }}
      ></iframe>
    </>
  );
};

export default HomeStatic;
