import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Constants from "src/config/Constants";
import styles from "src/Pages/Login/login.module.css";

// packages
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Button } from "react-bootstrap";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "src/_services/CustomFireBase";
import OTPInput from "otp-input-react";
import cryptoService from "src/_services/cryptoService";
import { isUndefined } from "lodash";
import { setCookie, getCookie } from "src/_helpers";
import {
  GetFirebaseSessionFromServer,
  SaveSessionToFirebaseInServer,
} from "src/_services/apimService";
import { userService } from "src/_services";
import { storeData } from "src/_services/localStore";
import { useSnackbar } from "notistack";
import devices from "src/assets/images/devices.svg";
import FullscreenLoader from "src/Components/Loaders/FullscreenLoader/FullscreenLoader";

const { APPNAME, LOGO, TERTIARY_COLOR } = Constants;

const LoginWithPhone = ({ setShowLoginWithPhone, setShowLoginWithQR }) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const recaptchaWrapperRef = useRef(null);
  const resendRecaptchaWrapperRef = useRef(null);

  const [showPhoneNumberScreen, setShowPhoneNumberScreen] = useState(true);
  const [dialCode, setDialCode] = useState("");
  const [phone, setPhone] = useState("");
  const [OTP, setOTP] = useState("");
  const [sessionId, setSessionId] = useState("");
  const [nextButtonClicked, setNextButtonClicked] = useState(false);
  const [verifyButtonClicked, setVerifyButtonClicked] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchSessionFromServer() {
      if (isUndefined(sessionId) || sessionId.length < 1) {
        let getSessionIdFromCookie = await getCookie(
          `${Constants.COOKIES_KEY.session_id}`
        );
        if (!getSessionIdFromCookie) {
          toggleLoading();
        }
        setSessionId(getSessionIdFromCookie);
        return;
      }
      let sessionDataFromServer = await GetFirebaseSessionFromServer(sessionId);
      if (sessionDataFromServer.success) {
        // now redirect to web chat
        // window.location = `${Constants.WEB_CHAT_URL}${sessionId}`;
        navigate("/webchat", {
          state: {
            sessionId,
          },
        });
      } else {
        await setCookie(`${Constants.COOKIES_KEY.session_id}`, "", 0);
        toggleLoading();
      }
    }
    fetchSessionFromServer();
  }, [sessionId]);

  useEffect(() => {
    if (OTP.length === 6) {
      verifyOtp();
    }
  }, [OTP]);

  useEffect(() => {
    if (showPhoneNumberScreen && !loading) {
      generateCaptcha();
    }
  }, [showPhoneNumberScreen, loading]);

  // useEffect(() => firebaselogin("+917901826224"), [])
  // useEffect(() => firebaselogin("+919756447781"), [])

  function handleLoginViaQR() {
    setShowLoginWithPhone(false);
    setShowLoginWithQR(true);
  }

  function handleBack() {
    setOTP("");
    toggleScreens();
    toggleNextButtonState();
    setPhone("");
  }

  const firebaselogin = async (phonenumber) => {
    try {
      let generateOTPdata = cryptoService.encrypt({
        mobile: phonenumber,
        v2: true,
      });
      let res = await userService.generateOTPModel(generateOTPdata);

      if (res.name === "AxiosError") {
        return enqueueSnackbar(res.response.statusText, { variant: "error" });
      }

      res = cryptoService.decrypt(res);
      res = JSON.parse(res);
      if (res.status) {
        let uid = res.result.userId;
        let obj = {
          token: "111111",
          userId: uid,
          deviceType: "IOS",
          retainAvatar: true,
        };
        let enc = cryptoService.encrypt(obj);
        res = await userService.verifyOTPModel(enc);
        let data = JSON.parse(cryptoService.decrypt(res.data));
        data = data.result;
        data.userId = uid;
        data.mobile = phonenumber;
        data.jid = uid + Constants.MONGOOSE.JID_POSTFIX;
        await storeData(Constants.LOCAL_STORAGE.KEY.USER, data);
        // let userLocalData = await getData(Constants.LOCAL_STORAGE.KEY.USER);
        let SaveSessionToFirebaseInServerData =
          await SaveSessionToFirebaseInServer(data);
        if (SaveSessionToFirebaseInServerData.success) {
          // build session here and redirect
          await setCookie(
            `${Constants.COOKIES_KEY.session_id}`,
            SaveSessionToFirebaseInServerData.data.session_id
          );
          let session_id = await getCookie(
            `${Constants.COOKIES_KEY.session_id}`
          );
          localStorage.setItem("phone", phone);
          setSessionId(session_id);
        } else {
        }
        // error here
      }
    } catch (e) {
      console.log(`Error : `, e);
      enqueueSnackbar("Please try again later", { variant: "error" });
    }
  };

  function getOtp() {
    const phoneNumber = phone.replace(`+${dialCode}`, "");
    if (!phoneNumber) {
      return enqueueSnackbar("Phone number is required", { variant: "error" });
    }
    toggleNextButtonState();
    try {
      signInWithPhoneNumber(auth, phone, window.recaptchaVerifier)
        .then((confirmationResult) => {
          console.log("confirmationResult", confirmationResult);
          window.confirmationResult = confirmationResult;
          toggleScreens();
        })
        .catch((error) => {
          console.log(error?.code);
          let errorMsg;
          switch (error?.code) {
            case "auth/invalid-phone-number":
              errorMsg = "Invalid phone number";
              break;
            case "auth/missing-phone-number":
              errorMsg = "Please enter valid phone number";
              break;
            case "auth/too-many-requests":
              errorMsg = "Too many requests, please try again after sometime.";
              break;
            default:
              errorMsg = "An unknown error occured. please try again later";
              break;
          }
          enqueueSnackbar(errorMsg, { variant: "error" });
          generateCaptcha();
          toggleNextButtonState();
        });

      //storing number in localStorage
      localStorage.setItem("phone", phone);
    } catch (error) {
      console.log({ error });
      generateCaptcha();
      toggleNextButtonState();
      enqueueSnackbar("Please try again later", { variant: "error" });
    }
  }

  async function verifyOtp() {
    if (!OTP || OTP.length < 6) {
      return enqueueSnackbar(`Valid OTP is required`, { variant: "error" });
    }
    toggleVerifyButtonState();
    try {
      window?.confirmationResult
        .confirm(OTP)
        .then(async (result) => {
          console.log(result);
          await firebaselogin(result?.user?.phoneNumber);
        })
        .catch((error) => {
          console.log(error?.code);
          toggleVerifyButtonState();
          let errorMsg;
          if (error?.code === "auth/invalid-verification-code") {
            errorMsg = "Invalid OTP";
          } else {
            errorMsg = "An unknown error occured. please try again later";
          }
          enqueueSnackbar(errorMsg, { variant: "error" });
        });
    } catch (error) {
      enqueueSnackbar(
        error?.message || "An unknown error occured. please try again later",
        { variant: "error" }
      );
      console.log(error);
      toggleVerifyButtonState();
    }
  }

  const handleResendOTP = async () => {
    try {
      generateResendCaptcha();
      signInWithPhoneNumber(auth, phone, window?.recaptchaVerifier)
        .then((confirmationResult) => {
          enqueueSnackbar("OTP resent!", { variant: "success" });
          window.confirmationResult = confirmationResult;
        })
        .catch((error) => {
          console.log(error.message);
          enqueueSnackbar("Please try again later", { variant: "error" });
          generateResendCaptcha();
        })
        .finally(() => localStorage.setItem("phone", phone));
    } catch (error) {
      console.log(error.message);
      enqueueSnackbar("Please try again later", { variant: "error" });
      generateResendCaptcha();
    }
  };

  function generateCaptcha() {
    try {
      const elementId = "recaptcha-container";
      if (recaptchaWrapperRef?.current) {
        recaptchaWrapperRef.current.innerHTML = `<div id=${elementId}></div>`;
      }
      window.recaptchaVerifier = new RecaptchaVerifier(elementId, {}, auth);
    } catch (e) {
      console.log(e);
    }
  }

  function generateResendCaptcha() {
    try {
      const elementId = `resend-recaptcha-container`;
      if (resendRecaptchaWrapperRef?.current) {
        resendRecaptchaWrapperRef.current.innerHTML = `<div id=${elementId}></div>`;
      }
      window.recaptchaVerifier = new RecaptchaVerifier(
        elementId,
        { size: "invisible" },
        auth
      );
    } catch (e) {
      console.log(e);
    }
  }

  function toggleNextButtonState() {
    setNextButtonClicked((current) => !current);
  }

  function toggleVerifyButtonState() {
    setVerifyButtonClicked((current) => !current);
  }

  function toggleLoading() {
    setLoading((prev) => !prev);
  }

  function toggleScreens() {
    setShowPhoneNumberScreen((prev) => !prev);
  }

  return (
    <>
      {loading ? (
        <FullscreenLoader />
      ) : showPhoneNumberScreen ? (
        <div className={`d-flex flex-column align-items-center`}>
          <div
            className={`d-flex flex-column align-items-center ${styles.upperSection}`}
          >
            <h2>
              Welcome to{" "}
              <img className={styles.logoStyles} src={LOGO} alt="logo" />
            </h2>
            <h6>The Complete Chat Platform</h6>
            <p className={styles.confirmCodeTxt}>
              Please confirm your country code and enter <br /> your phone
              number
            </p>
            <div className="input-group d-flex flex-column align-items-center">
              <PhoneInput
                country={"in"}
                enableSearch
                disableSearchIcon
                className="mt-1 phoneInput"
                name="phone_number"
                value={phone}
                onChange={(_value, country, _e, formattedValue) => {
                  setDialCode(country.dialCode);
                  setPhone(formattedValue.replaceAll(" ", ""));
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    getOtp();
                  }
                }}
              />
              <div
                className={`${!nextButtonClicked && "d-none"} mt-3`}
                ref={recaptchaWrapperRef}
              ></div>
              <Button
                className={`${styles.nextBtn} ${
                  APPNAME.toLowerCase() === "hookzapp"
                    ? styles.hookzappPrimaryColor
                    : styles.hookzappSecondaryColor
                }`}
                size="lg"
                onClick={getOtp}
                disabled={nextButtonClicked}
              >
                {nextButtonClicked ? "Please verify reCaptcha" : "Next"}
              </Button>
            </div>
          </div>
          <div className={`d-flex align-items-center ${styles.buttonSection}`}>
            <Button
              className={`${styles.qrBtn} ${
                APPNAME.toLowerCase() === "hookzapp"
                  ? styles.hookzappSecondaryColor
                  : styles.hookzappPrimaryColor
              }`}
              size="lg"
              onClick={handleLoginViaQR}
            >
              Login via QR code
            </Button>
          </div>
        </div>
      ) : (
        <div
          className={`d-flex flex-column align-items-center ${styles.enterOtpSection}`}
        >
          <img src={devices} alt="HookZApp" />
          <h5 className={styles.checkMessageTxtSection}>
            Check your HookZApp messages
          </h5>
          <p>
            We sent the code to your
            {APPNAME.toLowerCase() === "hookzapp"
              ? " HookZApp "
              : " mobile number "}
            <span>{phone}</span>
          </p>
          {!verifyButtonClicked && (
            <p>
              <span>Not you mobile number?</span>
              &nbsp; &nbsp;
              <span
                className={`${styles.linkStyle} ${
                  APPNAME.toLowerCase() === "hookzapp"
                    ? styles.hookzappPrimaryTextColor
                    : ""
                }`}
                onClick={handleBack}
              >
                Click Here
              </span>
            </p>
          )}
          <OTPInput
            value={OTP}
            onChange={setOTP}
            autoFocus
            OTPLength={6}
            otpType="number"
            disabled={false}
            inputStyles={{
              background: TERTIARY_COLOR,
              border: "none",
              borderRadius: 5,
              marginTop: 50,
              height: 50,
              width: 50,
            }}
          />
          <div ref={resendRecaptchaWrapperRef}></div>
          <div className="input-group d-flex flex-column align-items-center mt-5">
            <Button
              size="lg"
              onClick={verifyOtp}
              disabled={verifyButtonClicked}
              className={`col-4 ${styles.verifyBtn} ${
                APPNAME.toLowerCase() === "hookzapp"
                  ? styles.hookzappPrimaryColor
                  : styles.hookzappSecondaryColor
              }`}
            >
              {verifyButtonClicked ? "Please wait" : "Verify"}
            </Button>
          </div>
          <div className="d-flex justify-content-between mt-4">
            <div
              className={`${styles.didNotGetCode}`}
              onClick={handleResendOTP}
            >
              Didn't get the code?
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LoginWithPhone;
