import { GlobalConfig as config } from '../config';
import { isUndefined } from "lodash";
export const storeData = async function (key,data) {
  return new Promise(async (resolve, reject) => {
    try{
      if(isUndefined(key)) return "key is undefined";
      if(isUndefined(data)) return "data is undefined";
      removeData(key);
      resolve(localStorage.setItem(key, JSON.stringify(data)));
      }catch(error){
        reject(error);
    }
  })
}

export const getData = async function (key) {
  return new Promise(async (resolve, reject) => {
    try{
      if(isUndefined(key)) return "key is undefined";
      resolve(localStorage.getItem(key))
      }catch(error){
      reject(error);
    }
  })
}

export const removeData = async function (key) {
  return new Promise(async (resolve, reject) => {
    try{
      if(isUndefined(key)) return "key is undefined";
      let getData = localStorage.getItem(key);
      if(getData)
      {
        resolve(localStorage.removeItem(key))
      }
      resolve(true)
      }catch(error){
      reject(error)
    }
  })
}
