export class VideoFetcher {
  static instance;
  videoStream;
  audioStream;

  // constructor() {}

  static getInstance() {
    if (!this.instance) {
      this.instance = new VideoFetcher();
    }
    return this.instance;
  }

  getAudioStream() {
    return new Promise((resolve, reject) => {
      if (!this.audioStream) {
        navigator.mediaDevices
          .getUserMedia({
            audio: true,
            video: false,
          })
          .then((stream) => {
            this.audioStream = stream;
            resolve(stream);
          })
          .catch((e) => reject(e));
      } else {
        // resolve(this.audioStream.clone());
        resolve(this.audioStream);
      }
    });
  }

  // getVideoStream() {
  //     return new Promise((resolve, reject) => {
  //         if (!this.videoStream) {
  //             navigator.mediaDevices
  //                 .getUserMedia({
  //                     audio: false,
  //                     video: { height: { ideal: 180 }, width: { ideal: 300 }, frameRate: 24 },
  //                 })
  //                 .then(stream => {
  //                     this.videoStream = stream
  //                     resolve(stream);
  //                 })
  //                 .catch(e => reject(e));
  //         } else {
  //             // resolve(this.videoStream.clone());
  //             resolve(this.videoStream);
  //         }
  //     })
  // }

  getVideoStream(deviceId = null) {
    return new Promise((resolve, reject) => {
      if (
        !this.videoStream ||
        (deviceId && this.currentDeviceId !== deviceId)
      ) {
        let constraints = {
          audio: false,
          video: {
            height: { ideal: 240 },
            width: { ideal: 400 },
            frameRate: 24,
          },
        };
        if (deviceId) {
          constraints = {
            audio: false,
            video: {
              deviceId: { exact: deviceId },
              height: { ideal: 240 },
              width: { ideal: 400 },
              frameRate: 24,
            },
          };
        }
        navigator.mediaDevices
          .getUserMedia(constraints)
          .then((stream) => {
            this.videoStream = stream;
            this.currentDeviceId = deviceId;
            resolve(stream);
          })
          .catch((e) => reject(e));
      } else {
        resolve(this.videoStream);
      }
    });
  }

  stopVideo() {
    this.videoStream?.getVideoTracks()?.[0]?.stop();
    this.videoStream?.stop?.();
    this.videoStream = null;
  }

  stopAudio() {
    this.audioStream?.getAudioTracks()?.[0]?.stop();
    this.audioStream?.stop?.();
    this.audioStream = null;
  }

  destroy() {
    this.stopAudio();
    this.stopVideo();
  }
}
