import React, { useEffect, useRef, useState } from "react";
import mutedMic from "src/assets/icons/muted-mic.svg";
import mutedMicWhite from "src/assets/icons/muted-mic-white.svg";
import endCall from "src/assets/icons/end-call.svg";
import Draggable from "react-draggable";

import { VideoFetcher } from "./VideoFetcher";

import styles from "./ongoingVideoCall.module.css";
import { publish } from "src/_helpers/custom-events";

const OngoingVideoCall = () => {
  const localVideoRef = useRef(null);
  const remoteVideoRef = useRef(null);

  const [localVideoStream, setLocalVideoStream] = useState(null);
  const [localMicStream, setLocalMicStream] = useState(null);
  // const [loadingLocalVideoStream, setLoadingLocalVideoStream] = useState(true);

  useEffect(() => {
    async function getMediaDevices() {
      await resetAllDevices();
      getMic();
      getCamera();
      // let videoWrapper = document.querySelectorAll(".videoForLandscape");
      // function handleResize() {
      //   // console.log("resized to: ", window.innerWidth, "x", window.innerHeight);
      //   if (window.innerHeight < 540) {
      //     videoWrapper[0].style.height = "calc(100vh - 125px)";
      //   } else {
      //     videoWrapper[0].style.height = "auto";
      //   }
      // }
      // window.addEventListener("resize", handleResize);
    }
    getMediaDevices();
  }, []);

  useEffect(() => {
    if (!localVideoStream || !localVideoRef.current || !remoteVideoRef.current)
      return;
    let localVideo = localVideoRef.current;
    let remoteVideo = remoteVideoRef.current;
    localVideo.srcObject = localVideoStream;
    localVideo.play();
    remoteVideo.srcObject = localVideoStream;
    remoteVideo.play();
    // togglelocalVideoStreamLoading();
  }, [localVideoStream, localVideoRef]);

  async function resetAllDevices() {
    return new Promise((resolve, reject) => {
      if (localMicStream) {
        VideoFetcher.getInstance().stopAudio();
        setLocalMicStream(null);
      }
      if (localVideoStream) {
        VideoFetcher.getInstance().stopVideo();
        setLocalVideoStream(null);
      }
      resolve();
    });
  }

  function getMic() {
    VideoFetcher.getInstance()
      .getAudioStream()
      .then((stream) => {
        setLocalMicStream(stream);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }

  function getCamera() {
    VideoFetcher.getInstance()
      .getVideoStream()
      .then((stream) => {
        setLocalVideoStream(stream);
      })
      .catch((err) => {
        alert("Access denied for video: " + err.message);
        console.log("Access denied for video: ", err);
      });
  }

  // function togglelocalVideoStreamLoading() {
  //   setLoadingLocalVideoStream((current) => !current);
  // }

  function handleEndCall() {
    resetAllDevices();
    publish("endVideoCall");
    // toggleIncomingCall();
  }

  return (
    <Draggable>
      <div className={`${styles.primaryColor} ${styles.mainDiv}`}>
        <video
          ref={localVideoRef}
          playsInline
          webkitplaysinline={1}
          autoPlay
          style={{
            width: "100%",
            height: "100%",
            WebkitTransform: "scale(-1)",
            transform: "scaleX(-1)",
            position: "absolute",
            zIndex: 0,
          }}
        />
        <div className="d-flex flex-row-reverse me-2 mt-3">
          <div
            className={`d-flex align-items-center flex-column-reverse  ${styles.participant}`}
          >
            <video
              ref={remoteVideoRef}
              playsInline
              webkitplaysinline={1}
              autoPlay
              style={{
                width: "100%",
                height: "100%",
                WebkitTransform: "scale(-1)",
                transform: "scaleX(-1)",
                zIndex: 0,
                borderRadius: "15px",
              }}
            />
            <img
              className={`ms-2 mb-2 ${styles.mutedMic}`}
              src={mutedMicWhite}
              alt="mic"
              height={"20px"}
              width={"15px"}
            />
          </div>
        </div>
        <div
          className={`d-flex align-items-center justify-content-center ${styles.mutedOuterDiv}`}
        >
          <div className={`${styles.mutedDiv} gap-2`}>
            <img className="ms-2 my-auto" src={mutedMic} alt="mic" />
            <p className={`me-2 my-auto`}>Abdulla's mic is muted</p>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-3">
          <img
            className={styles.endCall}
            src={endCall}
            alt="decline"
            onClick={handleEndCall}
          />
        </div>
      </div>
    </Draggable>
  );
};

export default OngoingVideoCall;
