import { GlobalConfig as config } from "../config";
const CryptoJS = require("crypto-js");
const key = config.aes_secret;

const encryptData = function (encryptedData) {
  let encryptedText = encryptedData;
  try {
    encryptedText = JSON.stringify(encryptedData);
  } catch (error) {
    encryptedText = encryptedData;
  }

  try {
    const iv = CryptoJS.lib.WordArray.random(16);
    var cipher = CryptoJS.AES.encrypt(
      encryptedText,
      CryptoJS.enc.Hex.parse(key),
      { iv: iv }
    );
    return { iv: iv.toString(), encryptedData: cipher.ciphertext.toString() };
  } catch (error) {
    console.log(`Error in encryptData : `, error);
    return error;
  }
};

const decryptData = function (objectData) {
  let jsonData = objectData;
  try {
    jsonData = JSON.parse(objectData);
  } catch (error) {
    jsonData = objectData;
  }
  try {
    var cipher = CryptoJS.algo.AES.createDecryptor(
      CryptoJS.enc.Hex.parse(key),
      { iv: CryptoJS.enc.Hex.parse(jsonData.iv) }
    );
    let datacipher = cipher
      .process(CryptoJS.enc.Hex.parse(jsonData.encryptedData))
      .concat(cipher.finalize())
      .toString(CryptoJS.enc.Utf8);
    return datacipher;
  } catch (error) {
    console.log(`Error in decryptData : `, error);
    return error;
  }
};

export default {
  encrypt: encryptData,
  decrypt: decryptData,
};
