import React from "react";
import endCall from "src/assets/icons/end-call.svg";
import avatar from "src/assets/icons/bigger-avatar.svg";
import accept from "src/assets/icons/accept-voice-call.svg";
import lock from "src/assets/icons/lock.svg";
import cross from "src/assets/icons/cross.svg";

import styles from "./incomingCall.module.css";

import Draggable from "react-draggable";
import { publish } from "src/_helpers/custom-events";

const IncomingCall = ({ callType }) => {
  function handleCall(event) {
    if (typeof event === "object") {
      if (callType === 1) {
        event = "voiceCallAccepted";
      } else {
        event = "videoCallAccepted";
      }
    }
    publish(event);
  }

  function closePopUp() {
    publish("closePopUp");
  }

  return (
    <Draggable>
      <div className={`${styles.primaryColor} ${styles.mainDiv}`}>
        <img
          className="ms-2 mt-2"
          src={cross}
          alt="hide"
          onClick={closePopUp}
        />
        <div
          className={`${styles.primaryColor} d-flex flex-column align-items-center justify-content-center`}
        >
          <div className="d-flex gap-2 align-items-baseline mt-3">
            <img src={lock} alt="video" />
            <p>End-to-end encrypted</p>
          </div>
          <h2>Abdulla</h2>
          <h5>WhatsApp {callType === 1 ? "voice" : "video"} call</h5>
        </div>
        <div className="d-flex justify-content-center mt-3">
          <img src={avatar} alt="user" />
        </div>
        <div className={`d-flex justify-content-around mt-5 mb-3`}>
          <div className={styles.btnsDiv}>
            <img
              src={endCall}
              alt="decline"
              onClick={() => handleCall("callDeclined")}
            />
            <p>Decline</p>
          </div>
          <div className={styles.btnsDiv} onClick={handleCall}>
            <img src={accept} alt="accept" />
            <p>Accept</p>
          </div>
        </div>
      </div>
    </Draggable>
  );
};

export default IncomingCall;
