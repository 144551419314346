import React from "react";
import Constants from "src/config/Constants";
import { useLocation } from "react-router-dom";

function WebChat({ sessionId }) {
  const { state } = useLocation();

  return (
    <iframe
      src={`${Constants.WEB_CHAT_URL}${state.sessionId}`}
      allow="camera *;microphone *"
      style={{ width: "100%", height: "100%", position: "absolute" }}
    ></iframe>
  );
}

export default WebChat;
