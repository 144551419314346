import React from "react";
import video from "src/assets/icons/video-within-circle.svg";
import mutedMic from "src/assets/icons/muted-mic-within-circle.svg";
import endCall from "src/assets/icons/end-call.svg";
import options from "src/assets/icons/options.svg";
import avatar from "src/assets/icons/grey-avatar.svg";
import lock from "src/assets/icons/lock.svg";

import Draggable from "react-draggable";

import styles from "./ongoingVoiceCall.module.css";
import { publish } from "src/_helpers/custom-events";

const OngoingVoiceCall = () => {
  function handleEndCall() {
    sendEvent("endVoiceCall");
  }

  function sendEvent(event) {
    publish(event);
  }

  return (
    <Draggable>
      <div className={`${styles.primaryColor} ${styles.mainDiv}`}>
        <div className={`d-flex align-items-center gap-3 pt-3`}>
          <img className="ms-2" src={avatar} alt="video" />
          <div>
            <div className="d-flex gap-2 align-items-baseline">
              <img src={lock} alt="video" />
              <p>End-to-end encrypted</p>
            </div>
            <h4>Abdulla</h4>
          </div>
          <div className={`${styles.iconsDiv} d-flex gap-2 ms-4`}>
            <img
              src={video}
              alt="video"
              onClick={() => sendEvent("switchToVideo")}
            />
            <img
              src={mutedMic}
              alt="mutedMic"
              onClick={() => sendEvent("muteMic")}
            />
            <img
              src={options}
              alt="options"
              onClick={() => sendEvent("showOptions")}
            />
            <img src={endCall} alt="decline" onClick={handleEndCall} />
          </div>
        </div>
        <div className="mt-5 mb-4 pb-4">
          ---------------------------------------------Your microphone is
          muted----------------------------------------
        </div>
      </div>
    </Draggable>
  );
};

export default OngoingVoiceCall;
