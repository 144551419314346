import React, { useEffect } from "react";
// import $ from 'jquery';
import { Link } from "react-router-dom";
import Footer from "src/Components/Footer/Footer";
import Header from "src/Components/Header/Header";
const google = window.google;

const Contact = () => {

    useEffect(() => {

      window.scrollTo(0, 0); // Scrolls to the top of the page

      var locations = [
        ["India", 20.593683, 78.962883, 4],
        ["UK", 55.378052, -3.435973, 4],
        ["DUBAI", 25.204849, 55.270782, 4],
      ];

      var map = new google.maps.Map(document.getElementById("map"), {
        zoom: 3,
        center: new google.maps.LatLng(40.693683, 50.962883),
        //center: new google.maps.LatLng(01.8, 10.3),
        mapTypeId: google.maps.MapTypeId.ROADMAP,

        // How you would like to style the map.
        // This is where you would paste any style found on Snazzy Maps.
        styles: [
          {
            featureType: "water",
            elementType: "geometry",
            stylers: [
              {
                color: "#e9e9e9",
              },
              {
                lightness: 17,
              },
            ],
          },
          {
            featureType: "landscape",
            elementType: "geometry",
            stylers: [
              {
                color: "#f5f5f5",
              },
              {
                lightness: 20,
              },
            ],
          },
          {
            featureType: "road.highway",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#ffffff",
              },
              {
                lightness: 17,
              },
            ],
          },
          {
            featureType: "road.highway",
            elementType: "geometry.stroke",
            stylers: [
              {
                color: "#ffffff",
              },
              {
                lightness: 29,
              },
              {
                weight: 0.2,
              },
            ],
          },
          {
            featureType: "road.arterial",
            elementType: "geometry",
            stylers: [
              {
                color: "#ffffff",
              },
              {
                lightness: 18,
              },
            ],
          },
          {
            featureType: "road.local",
            elementType: "geometry",
            stylers: [
              {
                color: "#ffffff",
              },
              {
                lightness: 16,
              },
            ],
          },
          {
            featureType: "poi",
            elementType: "geometry",
            stylers: [
              {
                color: "#f5f5f5",
              },
              {
                lightness: 21,
              },
            ],
          },
          {
            featureType: "poi.park",
            elementType: "geometry",
            stylers: [
              {
                color: "#dedede",
              },
              {
                lightness: 21,
              },
            ],
          },
          {
            elementType: "labels.text.stroke",
            stylers: [
              {
                visibility: "on",
              },
              {
                color: "#ffffff",
              },
              {
                lightness: 16,
              },
            ],
          },
          {
            elementType: "labels.text.fill",
            stylers: [
              {
                saturation: 36,
              },
              {
                color: "#333333",
              },
              {
                lightness: 40,
              },
            ],
          },
          {
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "transit",
            elementType: "geometry",
            stylers: [
              {
                color: "#f2f2f2",
              },
              {
                lightness: 19,
              },
            ],
          },
          {
            featureType: "administrative",
            elementType: "geometry.fill",
            stylers: [
              {
                color: "#fefefe",
              },
              {
                lightness: 20,
              },
            ],
          },
          {
            featureType: "administrative",
            elementType: "geometry.stroke",
            stylers: [
              {
                color: "#fefefe",
              },
              {
                lightness: 17,
              },
              {
                weight: 1.2,
              },
            ],
          },
        ],
      });

      //var infowindow = new google.maps.InfoWindow();

      var contentString0 =
        '<div id="content0">' +
        '<div id="bodyContent">' +
        "<p>INDIA</p>" +
        "</div>" +
        "</div>";

      var contentString1 =
        '<div id="content1">' +
        '<div id="bodyContent">' +
        "<p>UK </p>" +
        "</div>" +
        "</div>";

      var contentString2 =
        '<div id="content2">' +
        '<div id="bodyContent">' +
        "<p>DUBAI</p>" +
        "</div>" +
        "</div>";

      var marker, i;

      var infowindow0 = new google.maps.InfoWindow({
        content: contentString0,
        maxWidth: 350,
      });

      var infowindow1 = new google.maps.InfoWindow({
        content: contentString1,
        maxWidth: 350,
      });

      var infowindow2 = new google.maps.InfoWindow({
        content: contentString2,
        maxWidth: 350,
      });
      //infowindow0.open(map,marker);
      //infowindow1.open(map,marker);

      for (i = 0; i < locations.length; i++) {
        marker = new google.maps.Marker({
          position: new google.maps.LatLng(
            locations[i][1],
            locations[i][2],
            locations[i][3]
          ),
          map: map,
          icon: "assets/img/placeholder.png",
        });

        google.maps.event.addListener(
          marker,
          "click",
          (function (marker, i) {
            return function () {
              if (i == "0") {
                infowindow0.open(map, marker);
              } else if (i == "1") {
                infowindow1.open(map, marker);
              } else if (i == "2") {
                infowindow2.open(map, marker);
              } else if (i == "3") {
                infowindow2.open(map, marker);
              }
            };
          })(marker, i)
        );
      }

  
        // $('[data-toggle="tooltip"]').tooltip();
     
    }, []);

  return (
    <div>
      {" "}
      <Header />
      {/* Start Page Title Area */}
      <div className="page-title-area">
        <div className="container">
          <div className="page-title-content">
            <h2>Contact Us</h2>
          </div>
        </div>
        <div className="divider" />
        <div className="lines">
          <div className="line" />
          <div className="line" />
          <div className="line" />
          <div className="line" />
          <div className="line" />
        </div>
        <div className="banner-shape1">
          <img src="assets/img/shape/shape9.png" alt="image" />
        </div>
      </div>
      {/* End Page Title Area */}
      <div className="contact-area" style={{ marginTop: 40 }}>
        <div className="container">
          <div className="section-title">
            <h2>Get in Touch</h2>
            <p>
              For platform related queries:{" "}
              <a
                href="mailto:support@hookzapp.com"
                title
                style={{ borderBottom: "1px solid red" }}
              >
                support@hookzapp.com
              </a>
            </p>
            <p>
              for everthing else:{" "}
              <a
                href="mailto:hello@hookzapp"
                title
                style={{ borderBottom: "1px solid red" }}
              >
                hello@hookzapp.com
              </a>
            </p>
          </div>
        </div>
      </div>{" "}
      <div id="map"> </div>
      {/* Start Footer Area */}
      <Footer/>
      {/* End Footer Area */}
      <div className="go-top">
        <i className="ri-arrow-up-s-line" />
      </div>
    </div>
  );
};

export default Contact;
