
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from 'firebase/auth'
import { GlobalConfig } from "../config";
// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: GlobalConfig.FIREBASE_CONFIG.apiKey,//"AIzaSyBRGxZZRbw9yssooOV1xs69Xfmh5cGBjTk",
  authDomain: GlobalConfig.FIREBASE_CONFIG.authDomain,//"scrambleim.firebaseapp.com",
  databaseURL: GlobalConfig.FIREBASE_CONFIG.databaseURL,//"https://scrambleim.firebaseio.com",
  projectId: GlobalConfig.FIREBASE_CONFIG.projectId,//"scrambleim",
  storageBucket: GlobalConfig.FIREBASE_CONFIG.storageBucket,//"scrambleim.appspot.com",
  messagingSenderId: GlobalConfig.FIREBASE_CONFIG.messagingSenderId,//"737043777275",
  appId: GlobalConfig.FIREBASE_CONFIG.appId,//"1:737043777275:web:c67f50cded45b3482c525e"
};  
  
  // Initialize Firebase
  const firebaseapp = initializeApp(firebaseConfig);
  export const auth = getAuth(firebaseapp)
  export const db = getFirestore(firebaseapp);
  export default firebaseapp;
