import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import QRCode from "react-qr-code";
import { v4 as uuidv4 } from "uuid";

import Constants from "src/config/Constants";
import settings from "src/assets/icons/settings.png";
import desktop from "src/assets/icons/desktop.png";
import checkedBox from "src/assets/icons/checked-box.png";
import uncheckedBox from "src/assets/icons/unchecked-box.png";
import uuid from "react-uuid";

import styles from "src/Pages/Login/login.module.css";
import { requestQr } from "src/_services/socketService";
import QrCodeLoader from "src/Components/Loaders/QrCodeLoader/QrCodeLoader";
import { useSnackbar } from "notistack";
import cryptoService from "src/_services/cryptoService";
import { getCookie, setCookie } from "src/_helpers";
import {
  GetFirebaseSessionFromServer,
  SaveSessionToFirebaseInServer,
} from "src/_services/apimService";
import { userService } from "src/_services";
import { storeData } from "src/_services/localStore";
import { db } from "src/_services/CustomFireBase";
import { doc, collection, onSnapshot, deleteDoc } from "firebase/firestore";

const { APPNAME, LOGO } = Constants;

const LoginWithQR = ({ setShowLoginWithQR, setShowLoginWithPhone }) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isKeepMeSignedIn, setIsKeepMeSignedIn] = useState(false);
  const [qrImage, setQrImage] = useState("");
  const [isQrloading, setQrLoading] = useState(false);
  const [sessionId, setSessionId] = useState("");
  const [qrCodeValue, setQrCodeValue] = useState(uuidv4());
  
  useEffect(() => {
    let qrCodeInterval = setInterval(() => {
      // let uniqueId = uuidv4();
      // setQrCodeValue(uniqueId);
    }, 30000);
    const unsub = onSnapshot(collection(db, qrCodeValue), (res) => {
      res.docChanges().forEach((change) => {
        // let localStorageUUIDv4 = "AXDSASV8957"
        // if(localStorageUUIDv4 === change.doc.data().UUIDv4){
        // Save change.doc.data() to localStorage or any other storage in encrypted form
        if (change.type === "added") {
          localStorage.setItem("chatData", JSON.stringify(change.doc.data()));
          let docRef = doc(db, qrCodeValue, change.doc.id);
          deleteDoc(docRef)
            .then(() => {
              firebaselogin(change.doc.data().phone_number)
              console.log(`${change.doc.id} deleted successfully123`);
            })
            .catch((err) => {
              console.log("err123 :>> ", err);
            });
        }

        // }
      });
    });
    return () => {
      clearInterval(qrCodeInterval);
      unsub();
    };
    // requestQr(uuid())
    //   .then((qrData) => {
    //     setQrImage(qrData.img);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     enqueueSnackbar("Please try again later", {
    //       variant: "error",
    //     });
    //   })
    //   .finally(() => toggleQrCodeLoading());

    // if(isLoggedIn()){
    //   xmppConnect();
    // }else{
    //   requestQr(uuid()).then(qrData=>{
    //     setQrImage(qrData.img);
    //   });
    // }
  }, []);

  useEffect(() => {
    // let sessionDataFromServer;
    async function fetchSessionFromServer() {
      if (!sessionId) {
        let getSessionIdFromCookie = await getCookie(
          `${Constants.COOKIES_KEY.session_id}`
        );
        setSessionId(getSessionIdFromCookie);
        return;
      }
      let sessionDataFromServer = await GetFirebaseSessionFromServer(sessionId);
      if (sessionDataFromServer.success) {
        // now redirect to web chat
        // window.location = `${Constants.WEB_CHAT_URL}${sessionId}`;
        navigate('/webchat', {
          state: {
            sessionId
          }
        });
      } else {
        await setCookie(`${Constants.COOKIES_KEY.session_id}`, "", 0);
      }
    }
    fetchSessionFromServer();
  }, [sessionId]);

  const firebaselogin = async (phonenumber) => {
    try {
      let generateOTPdata = cryptoService.encrypt({
        mobile: phonenumber,
        v2: true,
      });
      let res = await userService.generateOTPModel(generateOTPdata);

      if (res.name === "AxiosError") {
        return enqueueSnackbar(res.response.statusText, { variant: "error" });
      }

      res = cryptoService.decrypt(res);
      res = JSON.parse(res);
      if (res.status) {
        let uid = res.result.userId;
        let obj = {
          token: "111111",
          userId: uid,
          deviceType: "IOS",
          retainAvatar: true,
        };
        let enc = cryptoService.encrypt(obj);
        res = await userService.verifyOTPModel(enc);
        let data = JSON.parse(cryptoService.decrypt(res.data));
        data = data.result;
        data.userId = uid;
        data.mobile = phonenumber;
        data.jid = uid + Constants.MONGOOSE.JID_POSTFIX;
        await storeData(Constants.LOCAL_STORAGE.KEY.USER, data);
        // let userLocalData = await getData(Constants.LOCAL_STORAGE.KEY.USER);
        let SaveSessionToFirebaseInServerData =
          await SaveSessionToFirebaseInServer(data);
        if (SaveSessionToFirebaseInServerData.success) {
          // build session here and redirect
          await setCookie(
            `${Constants.COOKIES_KEY.session_id}`,
            SaveSessionToFirebaseInServerData.data.session_id
          );
          let session_id = await getCookie(
            `${Constants.COOKIES_KEY.session_id}`
          );
          setSessionId(session_id);
          // window.location = `${Constants.WEB_CHAT_URL}${session_id}`
        } else {
        }
        // error here
      }
    } catch (e) {
      console.log(`Error : `, e);
    }
  };

  function toggleQrCodeLoading() {
    setQrLoading((current) => !current);
  }

  function toggleKeepMeSignedInCheckBox() {
    setIsKeepMeSignedIn((current) => !current);
  }

  function handleLoginViaPhone() {
    setShowLoginWithQR(false);
    setShowLoginWithPhone(true);
  }

  return (
    <div
      className={`d-flex flex-column align-items-center justify-content-center ${styles.loginDiv}`}
    >
      <h1>Coming soon...</h1>
      <div className="d-flex flex-column align-items-center">
        <h2>
          Welcome to <img src={LOGO} alt="HookZApp" />
        </h2>
        <h6>The Complete Chat Platform</h6>
      </div>
      <div className="d-flex">
        <div className={`d-flex flex-column ${styles.instructions}`}>
          <h6>To use {APPNAME} on your computer/tablet :</h6>
          <p className="mt-3">1. Open {APPNAME} on your phone</p>
          <p>
            2. Tap Settings{" "}
            <span
              className={`${styles.qrIcons} ${
                APPNAME.toLowerCase() === "hookzapp"
                  ? styles.hookzappTertiaryColor
                  : styles.hookzappPrimaryColor
              }`}
            >
              <img src={settings} alt="settings" />
              <span className="settings-desktop-text">Settings</span>
            </span>{" "}
            and select Desktop{" "}
            <span
              className={`${styles.qrIcons} ${
                APPNAME.toLowerCase() === "hookzapp"
                  ? styles.hookzappTertiaryColor
                  : styles.hookzappPrimaryColor
              }`}
            >
              <img src={desktop} alt="desktop" />
              <span className="settings-desktop-text">Desktop</span>
            </span>
          </p>
          <p>3. Point your phone on this screen to capture the code</p>
          <p>
            4. Yay! You are now all set to enjoy {APPNAME} on your
            computer/tablet
            <br />
            <span
              className={`mt-2 ${styles.needHelpToGetStarted} ${
                APPNAME.toLowerCase() === "hookzapp"
                  ? styles.hookzappSecondaryTextColor
                  : styles.hookzappPrimaryTextColor
              }`}
              // style={{ color: `#${SECONDARY_COLOR}`, fontSize: 12 }}
            >
              Need help to get started?
            </span>
          </p>
        </div>
        <div className={`d-flex flex-column align-items-center ${styles.qr}`}>
          {/* <img src={qr} alt="qr" /> */}
          {isQrloading ? (
            <QrCodeLoader
              loaderHeight={50}
              loaderWidth={50}
              OuterDivHeight={"20vh"}
            />
          ) : (
            <>
              <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={qrCodeValue}
                viewBox={`0 0 256 256`}
              />
              <div
                className="d-flex m-4"
                onClick={toggleKeepMeSignedInCheckBox}
              >
                <img
                  src={isKeepMeSignedIn ? checkedBox : uncheckedBox}
                  alt="checkbox"
                />{" "}
                &nbsp; Keep me signed in
              </div>
            </>
          )}
        </div>
      </div>
      <div className="d-flex align-items-center">
        <Button
          className={`${styles.loginBtnStyle} ${
            APPNAME.toLowerCase() === "hookzapp"
              ? styles.hookzappSecondaryColor
              : styles.hookzappPrimaryColor
          }`}
          size="lg"
          onClick={handleLoginViaPhone}
        >
          Login via Phone number
        </Button>
      </div>
    </div>
  );
};

export default LoginWithQR;
