import React from "react";

const QrCodeLoader = ({
  OuterDivHeight,
  OuterDivWidth,
  loaderHeight,
  loaderWidth,
  loaderColor,
}) => {
  const outer_div_height = OuterDivHeight || "80vh";
  const height = loaderHeight || 75;
  const width = loaderWidth || 75;
  const color = loaderColor || "secondary";
  const outerDivStyles = { height: outer_div_height };
  if (OuterDivWidth) outerDivStyles.width = OuterDivWidth;
  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={outerDivStyles}
    >
      <div
        className={`spinner-border text-${color}`}
        style={{ height, width }}
      ></div>
    </div>
  );
};

export default QrCodeLoader;
