import { GlobalConfig } from "../config/";
import { authHeader } from "../_helpers";
import axios from "axios";
import { isUndefined } from "lodash";
var qs = require("qs");

export const SaveSessionToFirebaseInServer = async function (dataz) {
  return new Promise(async (resolve, reject) => {
    try {
      dataz = {
        clear_session_url: GlobalConfig.WEB_CHAT_CLEAR_SESSION_URL,
        syncContactsOnAppOpenViaCS:
          GlobalConfig.APIM_APIS.syncContactsOnAppOpenViaCS,
          userPepNodes:
          GlobalConfig.COMERA_BACKEND_URL.userPepNodes,
        ...dataz,
      };
      let stringifyData = { json_data: `'${JSON.stringify(dataz)}'` };
      const res = await axios.post(
        GlobalConfig.APIM_APIS.fireBaseSessionBuild,
        stringifyData
      );
      resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });
};
export const GetFirebaseSessionFromServer = async function (session_id) {
  return new Promise(async (resolve, reject) => {
    try {
      let session_idObj = { session_id: session_id };
      const res = await axios.post(
        GlobalConfig.APIM_APIS.getFireBaseSession,
        session_idObj
      );
      resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });
};
