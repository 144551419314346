import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { publish, subscribe, unsubscribe } from "src/_helpers/custom-events";
import IncomingCall from "./IncomingCall/IncomingCall";
import RequestingVideoCall from "./RequestingVideoCall/RequestingVideoCall";
import OngoingVideoCall from "./OngoingVideoCall/OngoingVideoCall";
import OngoingVoiceCall from "./OngoingVoiceCall/OngoingVoiceCall";

const Buttons = () => {
  const [showIncomingCall, setShowIncomingCall] = useState(false);
  const [showRequestingVideoCall, setShowRequestingVideoCall] = useState(false);
  const [showOngoingVoiceCall, setShowOngoingVoiceCall] = useState(false);
  const [showOngoingVideoCall, setShowOngoinCallVideo] = useState(false);
  const [callType, setCallType] = useState("");

  useEffect(() => {
    subscribeIncomingCall();
    subscribeVoiceCall();
    subscribeVideoCall();
    subscribeDeclineCall();
    subscribeClosePopUp();
    subscribeEndVoiceCall();
    subscribeEndVideoCall();
    subscribeSwitchToVideoCall();
    subscribeCancelRequestForVideoCall();

    return () => {
      unSubscribeIncomingCall();
      unSubscribeVoiceCall();
      unSubscribeVideoCall();
      unSubscribeDeclineCall();
      unSubscribeClosePopUp();
      unSubscribeEndVoiceCall();
      unSubscribeEndVideoCall();
      unSubscribeSwitchToVideoCall();
      unSubscribeCancelRequestForVideoCall();
    };
  }, []);

  function toggleIncomingCall() {
    setShowIncomingCall((current) => !current);
  }

  function toggleRequestingVideoCall() {
    setShowRequestingVideoCall((current) => !current);
  }

  function toggleOngoingVoiceCall() {
    setShowOngoingVoiceCall((current) => !current);
  }

  function toggleOngoingVideoCall() {
    setShowOngoinCallVideo((current) => !current);
  }

  function subscribeIncomingCall() {
    subscribe("incomingCall", toggleIncomingCall);
  }

  function unSubscribeIncomingCall() {
    unsubscribe("incomingCall", toggleIncomingCall);
  }

  function subscribeVoiceCall() {
    subscribe("voiceCallAccepted", () => {
      toggleOngoingVoiceCall();
      toggleIncomingCall();
    });
  }

  function unSubscribeVoiceCall() {
    unsubscribe("voiceCallAccepted", toggleOngoingVoiceCall);
  }

  function subscribeVideoCall() {
    subscribe("videoCallAccepted", () => {
      toggleOngoingVideoCall();
      toggleIncomingCall();
    });
  }

  function unSubscribeVideoCall() {
    unsubscribe("videoCallAccepted", toggleOngoingVideoCall);
  }

  function subscribeDeclineCall() {
    subscribe("callDeclined", toggleIncomingCall);
  }

  function unSubscribeDeclineCall() {
    unsubscribe("voiceCallDeclined", toggleIncomingCall);
  }

  function subscribeClosePopUp() {
    subscribe("closePopUp", toggleIncomingCall);
  }

  function unSubscribeClosePopUp() {
    unsubscribe("closePopUp", toggleIncomingCall);
  }

  function subscribeEndVoiceCall() {
    subscribe("endVoiceCall", toggleOngoingVoiceCall);
  }

  function unSubscribeEndVoiceCall() {
    unsubscribe("endVoiceCall", toggleOngoingVoiceCall);
  }

  function subscribeEndVideoCall() {
    subscribe("endVideoCall", toggleOngoingVideoCall);
  }

  function unSubscribeEndVideoCall() {
    unsubscribe("endVideoCall", toggleOngoingVideoCall);
  }

  function subscribeSwitchToVideoCall() {
    subscribe("switchToVideo", () => {
      toggleRequestingVideoCall();
      toggleOngoingVoiceCall();
    });
  }

  function unSubscribeSwitchToVideoCall() {
    unsubscribe("switchToVideo", () => {
      toggleRequestingVideoCall();
      toggleOngoingVoiceCall();
    });
  }

  function subscribeCancelRequestForVideoCall() {
    subscribe("cancelRequestForVideoCall", () => {
      toggleRequestingVideoCall();
      toggleOngoingVoiceCall();
    });
  }

  function unSubscribeCancelRequestForVideoCall() {
    unsubscribe("cancelRequestForVideoCall", () => {
      toggleRequestingVideoCall();
      toggleOngoingVoiceCall();
    });
  }

  return (
    <div>
      {showIncomingCall && <IncomingCall callType={callType} />}
      {showOngoingVoiceCall && <OngoingVoiceCall />}
      {showOngoingVideoCall && <OngoingVideoCall />}
      {showRequestingVideoCall && <RequestingVideoCall />}
      <div style={{ position: "absolute", bottom: 0 }}>
        <Button
          onClick={() => {
            publish("incomingCall", { type: 1 });
            setCallType(1);
          }}
        >
          Incoming Voice Call
        </Button>
        <Button
          onClick={() => {
            publish("incomingCall", { type: 2 });
            setCallType(2);
          }}
        >
          Incoming Video Call
        </Button>
      </div>
    </div>
  );
};

export default Buttons;
