import React, { useState } from "react";
import LoginWithPhone from "./LoginWithPhone/LoginWithPhone";
import LoginWithQR from "./LoginWithQR/LoginWithQR";

const Login = () => {
  const [showLoginWithQr, setShowLoginWithQR] = useState(false);
  const [showLoginWithPhone, setShowLoginWithPhone] = useState(true);
  return (
    <>
      {showLoginWithQr && (
        <LoginWithQR
          setShowLoginWithQR={setShowLoginWithQR}
          setShowLoginWithPhone={setShowLoginWithPhone}
        />
      )}
      {showLoginWithPhone && (
        <LoginWithPhone
          setShowLoginWithPhone={setShowLoginWithPhone}
          setShowLoginWithQR={setShowLoginWithQR}
        />
      )}
    </>
  );
};

export default Login;
