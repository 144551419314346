import { GlobalConfig } from "../config/";
import { authHeader } from "../_helpers";
import axios from "axios";

export const userService = {
  talkToSalesSubmit,
  userSignUp,
  generateOTPModel,
  verifyOTPModel,
  sendMail,
};

function talkToSalesSubmit(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    `${GlobalConfig.baseUrl}/api/talk-to-sales`,
    requestOptions
  ).then(handleResponse);
}

function userSignUp(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(`${GlobalConfig.baseUrl}/api/sign-up`, requestOptions).then(
    handleResponse
  );
}
function generateOTPModel(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  // return fetch(`${GlobalConfig.baseRegUrl}/c091a090-fa2a-468b-91f8-e4004acdbb05`, requestOptions).then(
  //   handleResponse
  // );
  return axios
    .post(
      `${GlobalConfig.baseRegUrl}/c091a090-fa2a-468b-91f8-e4004acdbb05`,
      data
    )
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
}
function verifyOTPModel(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  // return fetch(`${GlobalConfig.baseRegUrl}/9dc06637-4db9-49b7-8712-ddbe7cf688f8`, requestOptions).then(handleResponse);
  return axios
    .post(
      `${GlobalConfig.baseRegUrl}/9dc06637-4db9-49b7-8712-ddbe7cf688f8`,
      data
    )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
}

function sendMail(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  // return fetch(GlobalConfig.SEND_GRID_API_URL, requestOptions).then(
  //   handleResponse
  // );

  return axios
  .post(
    `${GlobalConfig.SEND_GRID_API_URL}`,
    JSON.stringify(data)
  )
  .then(function (response) {
    return response.data;
  })
  .catch(function (error) {
    console.log(error);
    return error;
  });
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        // logout();
        // location.reload(true);
      }

      // const error = (data && data.message) || response.statusText;
      const error = data || response.statusText;
      if (error.hasOwnProperty("message")) {
        return Promise.reject(error.message);
      } else {
        let errorArray = Object.values(error.errors);
        return Promise.reject(errorArray[0]); // show first error
      }

      // errorArray.map((val) => {
      //   return Promise.reject(val);
      // });
    }
    return data;
  });
}
