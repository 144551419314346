import { io } from "socket.io-client";
// import { jwtDecode,  } from 'jwt-js-decode';

const socket = io(process.env.REACT_APP_SOCKET_URL);


export const requestQr = (room) => {
  return new Promise((resolve, reject) => {
    try {
      socket.emit("qr-request", room);
      socket.on("qr-response", (message) => {
        socket.emit("login-request", {
          room: room,
          token:
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzZWNyZXRLZXkiOiI2NjRmMmYzNzYzNTYyNDUwMzAyNTVlMzY1NDQ5Mzc1YiIsIml2S2V5IjoiNGM3NjczMjEyNjNjNzIyNiIsImljZV9zZXJ2ZXJfdHVybiI6InR1cm46dHVybi5kYWFraWEuY28uaW46MzQ3OCIsImljZV9zZXJ2ZXJfc3R1biI6InN0dW46c3R1bi5kYWFraWEuY28uaW46MzQ3OCIsImljZV91c2VybmFtZSI6Imphc3ByZWV0IiwiaWNlX3Bhc3N3b3JkIjoiMTIzNDU2NzgiLCJ1c2VybmFtZSI6IjcxMTYyOTQ5LWVkNGMtNGRiYi1iNDdkLTM3OTY1YjJiODAzOSIsIm1vYmlsZSI6IjkxOTg1NTU1NjAwNiIsImlhdCI6MTY1Nzg2NDI2MiwiZXhwIjoxNjg5NDIxODYyfQ.jUhC0ijSJRwgv-LWDK4MAggdRfxCdPSRstKtUgStagk",
          mobile_device_id: 9108,
        });
        // console.log("login-request", message);
        resolve(message);
      });
      socket.on("login-response", (message) => {
        // const decodedToken = jwtDecode(message.token);
        // console.log({decodedToken})
        resolve(message);
      });
    } catch (error) {
      console.log("error", error);
      reject(error);
    }
  });
};
