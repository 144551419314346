export function authHeader() {
    // return authorization header with jwt token
    let jwt = localStorage.getItem("user");
  
    const header = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };
  
    if (jwt) header.Authorization = `Bearer ${jwt}`;
    return header;
}