import Home from "src/Pages/Home/Home";
import ClearSession from "./Pages/ClearSession/ClearSession";
import Buttons from "./Pages/Calls/Buttons";

import "src/App.css";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./Pages/Login/Login";
import Contact from "./Pages/Contact/Contact";
import Careers from "./Pages/Careers/Careers";
import About from "./Pages/About/About";
import Business from "./Pages/Business/Business";
import Jobs from "./Pages/Jobs/Jobs";
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy";
import Register from "./Pages/Register/Register";
import TermsAndConditions from "./Pages/TermsAndConditions/TermsAndConditions";
import WebChat from "./Pages/Login/LoginWithPhone/WebChat";

import './App.css'
import HomeStatic from "./Pages/HomeStatic/HomeStatic";

function App() {
  return (
    <BrowserRouter>
      <Routes>
      <Route path="/" element={<HomeStatic />} />
        <Route path="/login" element={<Login />} />
        <Route path="/clear-sessions" element={<ClearSession />} />
        <Route path="/contact" element={<HomeStatic />} />
        <Route path="/careers" element={<HomeStatic />} />
        <Route path="/about" element={<HomeStatic />} />
        {/* <Route path="/about" element={<About />} /> */}
        <Route path="/business" element={<HomeStatic />} />
        <Route path="/jobs" element={<HomeStatic />} />
        {/* <Route path="/terms-conditions.html" element={<TermsAndConditions />} /> */}
        <Route path="/terms-conditions.html" element={<HomeStatic />} />
        {/* <Route path="/privacy-policy.html" element={<PrivacyPolicy />} /> */}
        <Route path="/privacy-policy.html" element={<HomeStatic />} />
        <Route path="/register" element={<HomeStatic />} />
        <Route path="/buttons" element={<Buttons />} />
        <Route path="/webchat" element={<WebChat />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
